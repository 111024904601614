import React, { useState } from 'react';
import axios from 'axios';
import './SectionsTable.css';

function SectionsTable() {
    const [selectedCollection, setSelectedCollection] = useState('');
    const [sections, setSections] = useState([]);
    const [message, setMessage] = useState('');
    const [maxPersons, setMaxPersons] = useState(0);
    const [maxCompanys, setMaxCompanys] = useState(0);

    // Define the mapping of collection keys to their labels
    const collectionLabels = {
        decisions: 'القرارات',
        laws: 'القوانين',
        Decrees: 'المراسيم',
        LegislativeDecrees: 'المراسيم التشريعية',
        radios: 'الاذاعات',
        newsletters: 'النشرات',
        advertisements: 'الاعلانات',
        OfficialAnnouncements: 'الاعلانات الرسمية',
        judicialDecisions: 'الاحكام القضائية',
        assemblyMemoirs: 'مذكرات مجلس الشعب'
    };

    const fetchSections = async (collectionName) => {
        try {
            const res = await axios.get(`https://node.syriaonrecord.com/sections/${collectionName}`);
            console.log('Fetched sections:', res.data);
            setSections(res.data);
            setMessage('');
            determineMaxPersons(res.data);
            determineMaxCompanys(res.data);
        } catch (error) {
            console.error('Error fetching sections:', error);
            setMessage(error.response && error.response.status === 404 ? 'Collection not found.' : 'Error fetching sections.');
            setSections([]);
        }
    };

    const determineMaxPersons = (sections) => {
        let max = 0;
        sections.forEach(section => {
            if (section.namePersons && section.namePersons.length > max) {
                max = section.namePersons.length;
            }
        });
        setMaxPersons(max);
    };

    const determineMaxCompanys = (sections) => {
        let max = 0;
        sections.forEach(section => {
            if (section.nameCompanys && section.nameCompanys.length > max) {
                max = section.nameCompanys.length;
            }
        });
        setMaxCompanys(max);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
    };

    return (
        <div className="SectionsTable">
            <h1>قاعدة البيانات</h1>
            <select
                value={selectedCollection}
                onChange={(e) => {
                    setSelectedCollection(e.target.value);
                    fetchSections(e.target.value);
                }}
            >
                <option value="">اختر النوع</option>
                {Object.keys(collectionLabels).map((key) => (
                    <option key={key} value={key}>
                        {collectionLabels[key]}
                    </option>
                ))}
            </select>
            <p>{message}</p>
            {sections.length > 0 ? (
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>اسم القرار</th>
                                <th>وصف القرار</th>
                                {[...Array(maxPersons)].map((_, index) => (
                                    <th key={index}>الشخص{index + 1}</th>
                                ))}
                                {[...Array(maxPersons)].map((_, index) => (
                                    <th key={index}>الأب{index + 1}</th>
                                ))}
                                {[...Array(maxPersons)].map((_, index) => (
                                    <th key={index}>الأم{index + 1}</th>
                                ))}
                                {[...Array(maxCompanys)].map((_, index) => (
                                    <th key={index}>الشركة{index + 1}</th>
                                ))}
                                <th>القسم</th>
                                <th>التاريخ</th>
                                <th>الوزارة</th>
                                <th>نص القرار</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sections.map((section, index) => (
                                <tr key={index}>
                                    <td>{section.name}</td>
                                    <td>{section.description}</td>
                                    {[...Array(maxPersons)].map((_, i) => (
                                        <td key={i}>
                                            {section.namePersons && section.namePersons[i] ? section.namePersons[i] : 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    {[...Array(maxPersons)].map((_, i) => (
                                        <td key={i}>
                                            {section.namePersonsFather && section.namePersonsFather[i] ? section.namePersonsFather[i] : 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    {[...Array(maxPersons)].map((_, i) => (
                                        <td key={i}>
                                            {section.namePersonsMother && section.namePersonsMother[i] ? section.namePersonsMother[i] : 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    {[...Array(maxCompanys)].map((_, i) => (
                                        <td key={i}>
                                            {section.nameCompanys && section.nameCompanys[i] ? section.nameCompanys[i] : 'لايوجد اسم'}
                                        </td>
                                    ))}
                                    <td>{section.part}</td>
                                    <td>{formatDate(section.date)}</td>
                                    <td>{section.ministry}</td>
                                    <td>
                                        <div className="scrollable-text">
                                            {section.text}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>{message || 'No sections found for this collection.'}</p>
            )}
        </div>
    );
}

export default SectionsTable;
