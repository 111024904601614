import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SearchPage.css';

function SearchPage() {
    const [name, setName] = useState('');
    const [part, setPart] = useState('');
    const [ministry, setMinistry] = useState('');
    const [collection, setCollection] = useState('');
    const [namePerson, setNamePerson] = useState('');
    const [namePersonFather, setNamePersonFather] = useState('');
    const [namePersonMother, setNamePersonMother] = useState('');
    const [nameCompany, setNameCompany] = useState('');
    const [results, setResults] = useState([]);
    const [message, setMessage] = useState('');
    const [maxPersons, setMaxPersons] = useState(0);
    const [maxCompanys, setMaxCompanys] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading] = useState(false);

    useEffect(() => {
        if (results.length > 0) {
            const maxPersonsCount = Math.max(
                ...results.map(result => Math.max(result.namePersons?.length || 0, result.namePersonsFather?.length || 0, result.namePersonsMother?.length || 0))
            );
            const maxCompanysCount = Math.max(...results.map(result => result.nameCompanys?.length || 0));
            setMaxPersons(maxPersonsCount);
            setMaxCompanys(maxCompanysCount);
        }
    }, [results]);

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            const params = {
                name,
                part,
                ministry,
                collection,
                namePerson,
                namePersonFather,
                namePersonMother,
                nameCompany,
            };
            const res = await axios.get('https://node.syriaonrecord.com/search', { params });
            setResults(res.data);
            setMessage(res.data.length ? '' : 'No results found.');
        } catch (error) {
            console.error('Error fetching search results:', error);
            setMessage('Error fetching search results.');
        }
    };

    const highlightText = (text, term) => {
        if (!term || typeof term !== 'string') return text;
        const parts = text.split(new RegExp(`(${term})`, 'gi'));
        return parts.map((part, index) => part.toLowerCase() === term.toLowerCase() ? <span key={index} className="highlight">{part}</span> : part);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
    };

    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setResults([]);
        setMessage('');
    };

    const handleDownload = () => {
        // Combine all result texts into a single string
        const textContent = results.map(result => result.text).join('\n\n');
        const blob = new Blob([textContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'search_results.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL object
    };

    return (
        <div className="SearchPage">
            <h1>البحث في قاعدة البيانات</h1>
            {!isLoggedIn ? (
                <button onClick={handleLogin}>إبدأ عملية البحث</button>
            ) : (
                <button onClick={handleLogout}>أغلق عملية البحث</button>
            )}
            {isLoggedIn && (
                <div className="search-container">
                    <div className="search-form">
                        <form onSubmit={handleSearch}>
                            <input type="text" placeholder="المرسوم/ القرار" value={name} onChange={(e) => setName(e.target.value)} />
                            <input type="text" placeholder="القسم" value={part} onChange={(e) => setPart(e.target.value)} />
                            <input type="text" placeholder="الوزارة" value={ministry} onChange={(e) => setMinistry(e.target.value)} />
                            <input type="text" placeholder="اسم الشخص " value={namePerson} onChange={(e) => setNamePerson(e.target.value)} />
                            <input type="text" placeholder="اسم الأب" value={namePersonFather} onChange={(e) => setNamePersonFather(e.target.value)} />
                            <input type="text" placeholder="اسم الأم" value={namePersonMother} onChange={(e) => setNamePersonMother(e.target.value)} />
                            <input type="text" placeholder="اسم الشركة" value={nameCompany} onChange={(e) => setNameCompany(e.target.value)} />
                            <select value={collection} onChange={(e) => setCollection(e.target.value)}>
                                <option value="">اختر النوع</option>
                                <option value="decisions">قرارات</option>
                                <option value="laws">القوانين</option>
                                <option value="Decrees">المراسيم</option>
                                <option value="LegislativeDecrees">المراسيم التشريعية</option>
                                <option value="radios">الاذاعات</option>
                                <option value="newsletters">النشرات</option>
                                <option value="advertisements">الاعلانات</option>
                                <option value="OfficialAnnouncements">الاعلانات الرسمية</option>
                                <option value="judicialDecisions">الاحكام القضائية</option>
                                <option value="assemblyMemoirs">مذكرات مجلس الشعب</option>
                            </select>
                            <button type="submit">Search</button>
                        </form>
                    </div>
                    <div className="results-container">
                        <p>{message}</p>
                        {loading && <p>Loading...</p>}
                        {results.length > 0 && (
                            <>
                                <button onClick={handleDownload}>Download Text</button>
                                {results.map((result, index) => (
                                    <div key={index} className="result-box">
                                        <h3>{highlightText(result.name, name)}</h3>
                                        <p>{result.description}</p>
                                        <div className="result-people">
                                            {[...Array(maxPersons)].map((_, personIndex) => (
                                                <div key={personIndex} className="result-person">
                                                    <strong>Person {personIndex + 1}: </strong>
                                                    {result.namePersons && result.namePersons[personIndex] ? highlightText(result.namePersons[personIndex], namePerson) : 'N/A'}
                                                </div>
                                            ))}
                                            {[...Array(maxPersons)].map((_, personIndex) => (
                                                <div key={personIndex} className="result-person">
                                                    <strong>Father {personIndex + 1}: </strong>
                                                    {result.namePersonsFather && result.namePersonsFather[personIndex] ? highlightText(result.namePersonsFather[personIndex], namePersonFather) : 'N/A'}
                                                </div>
                                            ))}
                                            {[...Array(maxPersons)].map((_, personIndex) => (
                                                <div key={personIndex} className="result-person">
                                                    <strong>Mother {personIndex + 1}: </strong>
                                                    {result.namePersonsMother && result.namePersonsMother[personIndex] ? highlightText(result.namePersonsMother[personIndex], namePersonMother) : 'N/A'}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="result-company">
                                            {[...Array(maxCompanys)].map((_, companyIndex) => (
                                                <div key={companyIndex} className="result-company-item">
                                                    <strong>Company {companyIndex + 1}: </strong>
                                                    {result.nameCompanys && result.nameCompanys[companyIndex] ? highlightText(result.nameCompanys[companyIndex], nameCompany) : 'N/A'}
                                                </div>
                                            ))}
                                        </div>
                                        <p><strong>Part: </strong>{result.part}</p>
                                        <p><strong>Date: </strong>{formatDate(result.date)}</p>
                                        <p><strong>Ministry: </strong>{result.ministry}</p>
                                        <div className="scrollable-text">{result.text}</div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SearchPage;
