import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import './RegisterPage.css';


function RegisterPage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState(''); // State for OTP
    const [isOtpSent, setIsOtpSent] = useState(false); // Track if OTP was sent
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

const registerUser = async (userData) => {
    setIsLoading(true);
    try {
        const res = await axios.post('https://node.syriaonrecord.com/api/register', userData);
        setIsOtpSent(true); // OTP sent, show OTP input
        setMessage('OTP sent to your email.');
    } catch (error) {
        console.error('Error registering:', error.response ? error.response.data : error.message);
        setMessage(error.response ? error.response.data.message : 'Registration failed');
    } finally {
        setIsLoading(false);
    }
};

const handleRegister = (e) => {
    e.preventDefault();
    const userData = {
        name,
        email,
        password,
    };
    registerUser(userData);
};

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const res = await axios.post('https://node.syriaonrecord.com/api/verify-otp', {
                email,
                otp,
            });

            if (res.status === 200) { // Check if response status is 200
                console.log("OTP verified, redirecting to login...");
                setMessage('Registration successful. Redirecting to login...');
                setTimeout(() => {
                    navigate('/login'); // Redirect to the login page after success
                }, 1500); // Optional delay for better UX
            }
        } catch (error) {
            console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
            setMessage(error.response ? error.response.data.message : 'OTP verification failed');
        } finally {
            setIsLoading(false);
        }
    };

    

    return (
        <div>
            <TopBar />
            <div className="register-page">
            <div className="register-container">
                <h1>Register</h1>
                {!isOtpSent ? (
                    <form onSubmit={handleRegister}>
                        <input
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            disabled={isLoading}
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isLoading}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={isLoading}
                        />
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? 'Registering...' : 'Register'}
                        </button>
                    </form>
                ) : (
                    <form onSubmit={handleVerifyOtp}>
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            disabled={isLoading}
                        />
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
                        </button>
                    </form>
                )}
                <p>{message}</p>
            </div>
            </div>
        </div>
    );
}

export default RegisterPage;
